
$(document).ready(function(){
  console.log("ready!!")


  $("#post_post_type").change(function(){
      var post_type = $(this).val()
      if (post_type == "place"){
        $("#pricing").hide()
      } else {
        $("#pricing").show();
      }
  })

  $('#post_address').keyup(delay(function (e) {
    var address = this.value
    var town = $( "#post_town_id option:selected" ).text();



    address = address+","+town

    console.log("address",address)
    console.log("town", town)

    $("#options").empty()
    $("#okaddress").hide()
    $("#post_latitude").val("")
    $("#post_longitude").val("")

    $.get("/geocode_endpoint?address="+address, function(data, status){
        console.log("results",data.results)

        if (data.results == undefined){
          $("#options").append("<p><b> No se han encontrado resultados para tu dirección. </b></p>")
          return
        }

        if (data.results.length > 0){

          $("#options").append("<span style='margin-top: 15px;'><b>Elige una dirección de la lista: </b></span>")


        } else {
            $("#options").append("<p><b> No se han encontrado resultados para tu dirección. </b></p>")
        }

        $.each(data.results, function(idx, result){ 
            var append = "<p style='cursor: pointer;' class='mt-1 alert alert-warning place' data-latitude='"+result.position.lat+"' data-address='"+result.address.freeformAddress+"' data-longitude='"+result.position.lon+"'>"+result.address.freeformAddress+", "+result.address.countrySecondarySubdivision+"</p>"
            $("#options").append(append)
        })


    });
    

  }, 300));



  $(document).on('click', '.place', function(){
    var lat = $(this).attr("data-latitude")
    var lon = $(this).attr("data-longitude")
    var address = $(this).attr("data-address")

    $("#post_latitude").val(lat)
    $("#post_longitude").val(lon)
    $("#post_address").val(address)
    $("#options").empty()
    $("#okaddress").show()

  })

  function delay(callback, ms) {
    var timer = 0;
    return function() {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }
  

})